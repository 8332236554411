<template>
  <!--begin::Table-->
  <div class="position-relative" style="min-height: 100vh !important">
    <div
      class="position-absolute"
      style="top: 50%; left: 50%; transform: translate(-50%, -50%); width: 80%"
    >
      <div class="overflow-x-auto w-100">
        <table
          class="table table-striped text-center rounded-0 overflow-hidden"
        >
          <thead class="text-white py-3" style="background-color: #4e046b">
            <th class="p-5">{{ $t("auth.date") }}</th>
            <th class="p-5">{{ $t("auth.actions") }}</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ item.date }}</td>
              <td>
                <a :href="item.link" target="_blank">
                  <button
                    class="btn btn-sm text-white fw-bold"
                    style="background-color: #4e046b"
                  >
                    {{ $t("auth.paidNow") }}
                  </button>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!--end::Table-->
</template>

<script>
export default {
  name: "Show",
  data() {
    return {
      pgLoading: this.$route.params.id ? true : false,
      refs: "invoices/pending",
      items: [],
    };
  },
  created() {
    if (this.$route.params.id) {
      this.fetchRow();
    }
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
      };
      const options = {
        url: this.refs + "/next-payments/" + this.$route.params.id,
        method: "GET",
        data: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;
          this.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
